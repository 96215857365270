import { defineStore } from 'pinia';
import { useMutation, useQuery } from '@tanstack/vue-query';
import {
  Configuration,
  FundingSourceAlert,
  FundingSourceWarning,
  LocationInterface,
  RequiredDocument,
  ResponseData,
} from '~/composables/types/RequiredDocument';

export const useAdminStore = defineStore(
  'admin-store',
  () => {
    const getDocuments = () => {
      return $fetch<ResponseData<RequiredDocument>>(`/admin/required-documents`, {
        ...globalFetchOptions(),
        method: 'get',
      });
    };

    const createDocument = () => {
      return useMutation((data: any) =>
        $fetch<{ success: boolean }>(`/admin/required-documents`, {
          ...globalFetchOptions(),
          method: 'POST',
          retry: false,
          body: data,
        })
      );
    };

    const updateDocument = (docId: string) => {
      return useMutation((data: any) =>
        $fetch<{ success: boolean }>(`/admin/required-documents/${docId}`, {
          ...globalFetchOptions(),
          method: 'PUT',
          retry: false,
          body: data,
        })
      );
    };

    const deleteDocument = (options: any) => {
      return useMutation(
        (data: any) =>
          $fetch<{ success: boolean }>(`/admin/required-documents/${data['docId']}`, {
            ...globalFetchOptions(),
            method: 'DELETE',
            retry: false,
          }),
        options
      );
    };

    const getConfigurations = () => {
      return $fetch<ResponseData<Configuration>>(`/admin/approval-notification/configurations`, {
        ...globalFetchOptions(),
        method: 'get',
      });
    };
    const createNotification = () => {
      return useMutation((data: any) =>
        $fetch<{ success: boolean }>(`/admin/approval-notification/configurations`, {
          ...globalFetchOptions(),
          method: 'POST',
          retry: false,
          body: data,
        })
      );
    };
    const updateConfiguration = (confId: string) => {
      return useMutation((data: any) =>
        $fetch<{ success: boolean }>(`/admin/approval-notification/configurations/${confId}`, {
          ...globalFetchOptions(),
          method: 'PUT',
          retry: false,
          body: data,
        })
      );
    };

    const deleteConfiguration = (options: any) => {
      return useMutation(
        (data: any) =>
          $fetch<{ success: boolean }>(`/admin/approval-notification/configurations/${data['confId']}`, {
            ...globalFetchOptions(),
            method: 'DELETE',
            retry: false,
          }),
        options
      );
    };

    const getLocations = (all?: boolean, locIds?: string) => {
      if (all === undefined) all = false;
      return $fetch<ResponseData<LocationInterface[]>>(`/admin/locations?allLocations=${all}&locIds=${locIds ?? ''}`, {
        ...globalFetchOptions(),
        method: 'get',
      });
    };

    const getConfigurationById = (id: string) => {
      return $fetch<ResponseData<Configuration>>(`/admin/approval-notification/configurations/${id}`, {
        ...globalFetchOptions(),
        method: 'get',
      });
    };

    const getFundingSourceById = (id: string) => {
      return $fetch<{ success: boolean; data: FundingSourceWarning }>(`/admin/funding-source-warning/configurations/${id}`, {
        ...globalFetchOptions(),
        method: 'get',
      });
    };

    const createFundingSourceConf = () => {
      return useMutation((data: any) =>
        $fetch<{ success: boolean }>(`/admin/funding-source-warning/configurations`, {
          ...globalFetchOptions(),
          method: 'POST',
          retry: false,
          body: data,
        })
      );
    };
    const updateFundingSourceConf = (confId: string) => {
      return useMutation((data: any) =>
        $fetch<{ success: boolean }>(`/admin/funding-source-warning/configurations/${confId}`, {
          ...globalFetchOptions(),
          method: 'PUT',
          retry: false,
          body: data,
        })
      );
    };

    const deleteFundingSourceConf = (options: any) => {
      return useMutation(
        (data: any) =>
          $fetch<{ success: boolean }>(`/admin/funding-source-warning/configurations/${data['confId']}`, {
            ...globalFetchOptions(),
            method: 'DELETE',
            retry: false,
          }),
        options
      );
    };
    const getFundingSourceConf = () => {
      return $fetch<ResponseData<FundingSourceWarning>>(`/admin/funding-source-warning/configurations`, {
        ...globalFetchOptions(),
        method: 'get',
      });
    };

    const updateFundingSourceEnabled = () => {
      return useMutation((data: any) =>
        $fetch<{ success: boolean }>(`/admin/funding-source-warning/configurations/${data.id}`, {
          ...globalFetchOptions(),
          method: 'PUT',
          retry: false,
          body: data,
        })
      );
    };

    const notifChecked = ref(false);
    const setNotifChecked = (val: boolean) => {
      notifChecked.value = val;
    };
    const getFundingSourceNotification = () => {
      notifChecked.value = true;
      return $fetch<ResponseData<FundingSourceAlert>>(`/admin/funding-source-warning/alerts`, {
        ...globalFetchOptions(),
        method: 'get',
      });
    };

    const getApplicationAndIncomeDocuments = (options: any) => {
      const fetcher = async () =>
        $fetch<{ success: boolean; data: ApplicationOrIncomeDocument }>(`/admin/application-income-documents`, {
          ...globalFetchOptions(),
          method: 'get',
        });
      return useQuery({ queryKey: ['app-income-docs'], queryFn: fetcher, ...globalUseQueryOptions(), ...options });
    };

    const reportOpen = ref(false);

    return {
      getDocuments,
      createDocument,
      updateDocument,
      deleteDocument,
      createNotification,
      getConfigurations,
      updateConfiguration,
      deleteConfiguration,
      getLocations,
      getConfigurationById,
      getFundingSourceById,
      getFundingSourceConf,
      deleteFundingSourceConf,
      updateFundingSourceConf,
      createFundingSourceConf,
      updateFundingSourceEnabled,
      getFundingSourceNotification,
      notifChecked,
      setNotifChecked,
      getApplicationAndIncomeDocuments,
      reportOpen,
    };
  },
  {
    persist: {
      storage: sessionStorage,
    },
  }
);
